<template>
  <v-container>
    <v-row v-if="pageIsLoading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-show="formIsSubmitted"
      transition="fade-transition"
      style="z-index: 1; position: fixed; right: 15px; min-width: 300px"
      color="success"
      type="success"
    >
      Successfully submitted!
    </v-alert>

    <v-alert
      v-show="pageIsError"
      transition="fade-transition"
      style="z-index: 1; position: fixed; right: 15px; min-width: 300px"
      color="error"
      type="error"
    >
      An error occurred!
    </v-alert>

    <v-row v-if="!pageIsLoading" justify="center">
      <v-col xs="12" xl="8">
        <v-row>
          <v-col>
            <h1>Add Objective</h1>

            <p class="grey--text">
              Define your goal: Clearly establish what you want to achieve
              through the deal. This could be securing a certain price,
              obtaining specific terms, expanding your customer base, increasing
              revenue, or improving your market position. Make sure your
              objective is specific, measurable, achievable, relevant, and
              time-bound (SMART).
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-tabs v-model="tab" icons-and-text class="green">
              <v-tab href="#manual_add">
                Add Manually
                <v-icon>mdi-plus</v-icon>
              </v-tab>

              <v-tab href="#quick_add">
                Quick Add
                <v-icon>mdi-flash-outline</v-icon>
              </v-tab>
            </v-tabs>

            <v-divider />

            <v-tabs-items v-model="tab" class="pa-5 bordered">
              <v-tab-item value="manual_add">
                <StageObjectiveForm
                  :stage_item="stg_itm"
                  :assignees="assignees"
                  :objective_templates="objective_templates"
                  @submit-notification="handleSubmitNotification"
                  ref="stageObjectiveForm"
                />
              </v-tab-item>

              <!-- quick add -->
              <v-tab-item value="quick_add">
                <v-row>
                  <v-col>
                    <div>
                      <v-row>
                        <v-col>
                          Filter by type:
                          <v-select
                            v-model="filtered_type"
                            :items="['Service Ticket', 'Internal Step']"
                            label="Select Type"
                            dense
                            outlined
                          />
                        </v-col>

                        <v-col v-if="filtered_type !== 'Service Ticket'">
                        </v-col>

                        <v-col v-if="filtered_type === 'Service Ticket'">
                          Filter by service ticket type:
                          <v-select
                            v-model="filtered_service_ticket_type"
                            :items="[
                              'Payout Request',
                              'Balance Request',
                              'Payment Amount Change',
                              'Payment Date Change',
                              'Payment Collection',
                              'Property Taxes',
                              'Insurance',
                              'Demand Letter',
                              'Foreclosure',
                              'Other',
                            ]"
                            label="Select Service Ticket Type"
                            dense
                            outlined
                          />
                        </v-col>
                      </v-row>
                    </div>

                    <v-tabs vertical>
                      <v-tab
                        v-for="objective in filtered_objective_templates"
                        :key="objective.id"
                        class="d-flex align-center justify-start"
                      >
                        {{ objective.subject }}
                      </v-tab>

                      <v-tab-item
                        v-for="objective in filtered_objective_templates"
                        :key="objective.id"
                      >
                        <v-card flat style="background-color: #111">
                          <v-card-text>
                            <v-simple-table>
                              <thead>
                                <tr>
                                  <th
                                    class="white--text text-center"
                                    :width="200"
                                  >
                                    FIELDS
                                  </th>
                                  <th class="white--text text-center">
                                    DETAILS
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td>SUBJECT</td>
                                  <td>{{ objective.subject }}</td>
                                </tr>

                                <tr>
                                  <td>DESCRIPTION</td>
                                  <td>{{ objective.description }}</td>
                                </tr>

                                <tr>
                                  <td>TYPE</td>
                                  <td>
                                    {{
                                      objective.type === "Service Ticket"
                                        ? `${objective.type} -  ${objective.service_ticket_type}`
                                        : objective.type
                                    }}

                                    {{
                                      objective.service_ticket_type === "Other"
                                        ? `- ${objective.specified_service_ticket_type}`
                                        : ""
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </v-card-text>

                          <v-card-text>
                            <v-row>
                              <v-col class="task-style d-flex">
                                <h2
                                  v-if="objective.obj_tsk_templs.length === 0"
                                  class="grey--text"
                                >
                                  ( No task yet )
                                </h2>
                                <v-data-table
                                  :headers="task_header"
                                  :items="objective.obj_tsk_templs"
                                  :items-per-page="-1"
                                >
                                  <template v-slot:item.description="{ item }">
                                    <div class="text-wrap">
                                      {{ item.description }}
                                    </div>
                                  </template>
                                </v-data-table>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-btn @click="pick_objective(objective.id)">
                                  <v-icon>mdi-gesture-tap</v-icon> Select this
                                  template
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>

                    <v-alert
                      type="warning"
                      v-if="filtered_objective_templates.length === 0"
                    >
                      There are no existing templates
                    </v-alert>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              v-if="$route.query.deal_id"
              @click="$router.push({ path: `/dashboard/admin/deal/read/${$route.query.deal_id}` })"
              class="mr-2"
            >
              view deal
            </v-btn>

            <v-btn @click="$router.go(-1)"> cancel </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import StageObjectiveForm from "@/components/StageObjectiveForm.vue";

export default {
  props: ["kanban_type", "stage_item_id"],
  components: { StageObjectiveForm },

  data: () => ({
    selectedItem: null,
    tab: "",
    pageIsLoading: true,
    pageIsError: false,
    formIsSubmitted: false,
    filtered_type: null,
    filtered_service_ticket_type: null,

    task_header: [
      { text: "Subject", value: "subject" },
      { text: "Description", value: "description" },
      { text: "", value: "actions", sortable: false },
    ],

    assignees: [],

    stg_itm: {},

    objective_templates: [],
  }),

  methods: {
    pick_objective(id) {
      this.tab = "manual_add";
      this.$refs.stageObjectiveForm.picked_objective(id);
    },

    handleSubmitNotification() {
      this.formIsSubmitted = true;

      setTimeout(() => {
        this.formIsSubmitted = false;
      }, 2000);

      setTimeout(() => {
        if (this.$route.query.deal_id) {
          this.$router.push({
            path: `/dashboard/admin/deal/read/${this.$route.query.deal_id}`,
          });
        } else {
          this.$router.go(-1);
        }
      }, 3000);
    },

    async readStageItem() {
      try {
        this.pageIsLoading = true;
        const res = await API().get(
          `api/kanban/read_stage_item/?stg_itm_id=${this.stage_item_id}`
        );

        // console.log(res.data);

        this.stg_itm = res.data;

        const obj_templs = await API().get(
          "api/kanban/get_objective_templates"
        );

        this.objective_templates = obj_templs.data;

        // console.log("objective templates: ", obj_templs.data);

        if (this.$route.query.view_type === "deal") {
          this.stg_itm.stg_objs = this.stg_itm.stg_objs.filter((obj) => {
            return obj.type === "Service Ticket";
          });
          0;
        }

        const res_users = await API().get("api/kanban/get_internal_users");

        this.assignees = res_users.data;

        this.pageIsLoading = false;
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },
  },

  computed: {
    filtered_objective_templates() {
      if (this.filtered_type === null) {
        return this.objective_templates;
      }

      if (this.filtered_type === "Service Ticket") {
        if (this.filtered_service_ticket_type) {
          return this.objective_templates.filter(
            (template) =>
              template.type === this.filtered_type &&
              template.service_ticket_type === this.filtered_service_ticket_type
          );
        } else {
          return this.objective_templates.filter(
            (template) => template.type === this.filtered_type
          );
        }
      }

      return this.objective_templates.filter(
        (template) => template.type === this.filtered_type
      );
    },
  },

  mounted() {
    this.readStageItem();
  },
};
</script>

<style>
.bordered {
  border: 1px solid rgb(51, 51, 51);
  padding: 5px;
}

.objective-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

/* height */
.objective-container::-webkit-scrollbar {
  height: 10px;
}

/* Track */
.objective-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
.objective-container::-webkit-scrollbar-thumb {
  background: rgb(97, 97, 97);
  border-radius: 10px;
}

/* Handle on hover */
.objective-container::-webkit-scrollbar-thumb:hover {
  background: rgb(49, 49, 49);
}

.circle .v-input--selection-controls__ripple {
  border-radius: 50%;
}

.circle .v-input--selection-controls__input {
  border-radius: 50%;
}

.circle .v-icon {
  border-radius: 50%;
}

.task-style {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

/* height */
.task-style::-webkit-scrollbar {
  height: 10px;
}

/* Track */
.task-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
.task-style::-webkit-scrollbar-thumb {
  background: rgb(97, 97, 97);
  border-radius: 10px;
}

/* Handle on hover */
.task-style::-webkit-scrollbar-thumb:hover {
  background: rgb(49, 49, 49);
}
</style>
